// * Dropdowns
// *******************************************************************************

@mixin template-dropdown-variant($parent, $background, $color: null) {
  #{$parent} .dropdown-item:not(.disabled).active,
  #{$parent} .dropdown-item:not(.disabled):active {
    background-color: $background;
    color: if($color, $color, color-contrast($background)) !important;
  }

  #{$parent}.dropdown-menu > li:not(.disabled) > a:not(.dropdown-item):active,
  #{$parent}.dropdown-menu > li.active:not(.disabled) > a:not(.dropdown-item) {
    background-color: $background;
    color: if($color, $color, color-contrast($background)) !important;
  }
}

@mixin template-dropdown-theme($background, $color: null) {
  @include template-dropdown-variant('', $background, $color);
}
