body {
  background-color: #f8f8f8 !important;
}

.menu .app-brand.demo {
  height: 64px;
  margin-top: 12px;
  margin-bottom: 20px;
}

.app-brand-logo.demo svg {
  width: 22px;
  height: 38px;
}

.app-brand-text.demo {
  font-size: 1.75rem;
  letter-spacing: -0.5px;
  text-transform: capitalize;
}

/* ! For .layout-navbar-fixed added fix padding top tpo .layout-page */
/* Detached navbar */
.layout-navbar-fixed .layout-wrapper:not(.layout-horizontal):not(.layout-without-menu) .layout-page {
  padding-top: 76px !important;
}
/* Default navbar */
.layout-navbar-fixed .layout-wrapper:not(.layout-without-menu) .layout-page {
  padding-top: 64px !important;
}

/* Navbar page z-index issue solution */
.content-wrapper .navbar {
  z-index: auto;
}

/*
* Content
******************************************************************************/

.demo-blocks > * {
  display: block !important;
}

.demo-inline-spacing > * {
  margin: 1rem 0.375rem 0 0 !important;
}

/* ? .demo-vertical-spacing class is used to have vertical margins between elements. To remove margin-top from the first-child, use .demo-only-element class with .demo-vertical-spacing class. For example, we have used this class in forms-input-groups.html file. */
.demo-vertical-spacing > * {
  margin-top: 1rem !important;
  margin-bottom: 0 !important;
}
.demo-vertical-spacing.demo-only-element > :first-child {
  margin-top: 0 !important;
}

.demo-vertical-spacing-lg > * {
  margin-top: 1.875rem !important;
  margin-bottom: 0 !important;
}
.demo-vertical-spacing-lg.demo-only-element > :first-child {
  margin-top: 0 !important;
}

.demo-vertical-spacing-xl > * {
  margin-top: 5rem !important;
  margin-bottom: 0 !important;
}
.demo-vertical-spacing-xl.demo-only-element > :first-child {
  margin-top: 0 !important;
}

.rtl-only {
  display: none !important;
  text-align: left !important;
  direction: ltr !important;
}

[dir='rtl'] .rtl-only {
  display: block !important;
}

/*
* Layout demo
******************************************************************************/

.layout-demo-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 1rem;
}
.layout-demo-placeholder img {
  width: 900px;
}
.layout-demo-info {
  text-align: center;
  margin-top: 1rem;
}
.template-color-preview {
  box-shadow: inset 0 0 0 1px rgb(0 0 0 / 20%);
  border-radius: 9999px;
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
  display: inline-block;
}
.ql-editor {
    .ql-video {
        width: 100%;
        min-height: 280px;
    }
    img {
        max-width: 100% !important;
    }
}

.ant-table-title {
  padding: 0 !important;
}

.ant-form-item-has-error .ant-input {
  background-color: #fff;
  border-color: #ff4d4f;
}
.ant-form-item-explain.ant-form-item-explain-error {
    color: #ff4d4f;
}

.ant-table-title {
  display: none;
}

.invalid-feedback {
  display: block;
}

.custom-input-error {
  border: 1px solid #ff3e1d !important;
  border-radius: 5px !important;
}

@media screen and (min-width: 768px) {
  .menu-item.open > .menu-sub {
    padding-left: 8px;
  }  
}

.eq-height {
  height: 100% !important;
}

/** ================= Modal ======================= */
.ant-modal-wrap {
  z-index: 99999999999;
}

/** =============== Premium Icon =============== */
.premium-icon-container {
    display: block;
    position: relative;
    .premium-icon {
        height: 25px!important;
        opacity: 100%;
        position: absolute;
        right: 5px;
        top: 5px;
        width: 25px!important;
        z-index: 99999999;
    }
}

/** =============== Plan Facilities =============== */
.plan-facilities {
  list-style: none;
  padding-left: 5px;
  li {
    color: #777e91 !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 10px;
    i {
      color: #45b26b !important;
      margin-right: 5px;
      font-weight: bold;
    }
  }
}

.strong {
    font-size: 15px;
    font-weight: 500;
}