// * Page Icons (Page specific only)
// *******************************************************************************

@import '../_bootstrap-extended/include';
@import '../_custom-variables/pages';

#icons-container {
  .icon-card {
    width: 128px;

    i {
      font-size: 2rem;
    }
  }
}

@media (max-width: 1024px) {
  #icons-container {
    .icon-card {
      width: 126px;
    }
  }
}
@include media-breakpoint-down(md) {
  #icons-container {
    .icon-card {
      width: 131px;
    }
  }
}

@media (max-width: 414px) {
  #icons-container {
    .icon-card {
      width: 110px;
    }
  }
}

@media (max-width: 375px) {
  #icons-container {
    .icon-card {
      width: 150px;
    }
  }
}
